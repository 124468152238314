import { useContext } from 'react';
import dynamic from 'next/dynamic';
import { LanguageContext } from 'context/Language';
import { StoreContext } from 'context/Store';
import { useTranslation } from 'hooks';
import { Article, Results } from 'types';
import { RowLeft, RowRight } from 'resources';

const Container = dynamic(() => import('components/Grid/Container'));
const Row = dynamic(() => import('components/Grid/Row'));
const Col = dynamic(() => import('components/Grid/Col'));
const Icon = dynamic(() => import('components/Icon'));
const Button = dynamic(() => import('components/Button'));
const Carousel = dynamic(() => import('components/Carousel'));
const ItemComponents = dynamic(() => import('components/Carousel/ItemComponents'));
const NewsCard = dynamic(() => import('components/NewsCard'));
const NewsCardHeader = dynamic(() => import('components/NewsCard/NewsCardHeader'));
const NewsCardBody = dynamic(() => import('components/NewsCard/NewsCardBody'));
const NewsCardFooter = dynamic(() => import('components/NewsCard/NewsCardFooter'));
const SliderCards = dynamic(() => import('components/SliderCards'));
const ButtonLink = dynamic(() => import('components/NewsCard/ButtonLink'));
const Responsive = dynamic(() => import('components/Responsive'));

interface WrapperProps {
  articleList: Results<Article>;
}

const Wrapper: React.FC<WrapperProps> = ({ articleList }) => {
  const { t } = useTranslation(['common']);
  const { lang } = useContext(LanguageContext);
  const { isMobile } = useContext(StoreContext);

  const dataNews = articleList.results;

  return (
    <Responsive>
      <Container>
        <NewsCard>
          <NewsCardHeader className="mt-30 mb-35">
            <Row>
              <Col>
                <h2 className="news-box__title">{t('news')}</h2>
              </Col>
              <Col size={12}>
                <div className="separated-border__lg" />
              </Col>
            </Row>
          </NewsCardHeader>
          <NewsCardBody>
            <>
              {!isMobile && dataNews && (
                <Carousel
                  type="components-news"
                  settings={{
                    autoplay: false,
                    dots: false,
                    prevArrow: (
                      <Button>
                        <Icon component={RowLeft} />
                      </Button>
                    ),
                    nextArrow: (
                      <Button>
                        <Icon component={RowRight} />
                      </Button>
                    ),
                  }}
                >
                  <ItemComponents>
                    <SliderCards data={dataNews.slice(0, 6)} />
                  </ItemComponents>

                  {dataNews.length > 6 && (
                    <ItemComponents>
                      <SliderCards data={dataNews.slice(6, 12)} />
                    </ItemComponents>
                  )}
                  {dataNews.length > 12 && (
                    <ItemComponents>
                      <SliderCards data={dataNews.slice(12, 18)} />
                    </ItemComponents>
                  )}
                </Carousel>
              )}
            </>
          </NewsCardBody>
          <NewsCardFooter>
            <Row g={5}>
              <Col size={6}>
                <ButtonLink href={`/${lang}/news`} visibleIcon />
              </Col>
            </Row>
          </NewsCardFooter>
        </NewsCard>
      </Container>
    </Responsive>
  );
};

export default Wrapper;
